import { PublicPages_GetCollective } from '@collective/data-type';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import { NextSeo } from 'next-seo';

import { backendClient } from '~/public-pages/clients/backend-client';
import { launchDarklyClient } from '~/public-pages/clients/launchdarkly-client';
import { CollectivePublicPage } from '~/public-pages/components/pages/collective-public-page';
import {
  DATADOG_PUBLIC_PAGES_APPLICATION_ID,
  DATADOG_SERVICE,
} from '~/public-pages/constants/datadog';
import { GOOGLE_TAG_MANAGER_ID } from '~/public-pages/constants/gtm';
import { PublicPageContextProvider } from '~/public-pages/contexts/public-page-context';
import { usePageReady } from '~/public-pages/hooks/use-page-ready';
import {
  collectiveNotFoundRedirect,
  toCollectiveCaseStudy,
} from '~/public-pages/router';
import { getCollectivePageUrl } from '~/public-pages/utils/urls';

export type CollectivePageProps = {
  collective: NonNullable<PublicPages_GetCollective['collective']>;
};

export type CollectivePageInputProps = { collectiveSlug: string };

export const getServerSideProps: GetServerSideProps<
  CollectivePageProps,
  CollectivePageInputProps
> = async ({ params }) => {
  if (!params?.collectiveSlug) {
    return collectiveNotFoundRedirect();
  }

  const [{ collective }, featureFlags] = await Promise.all([
    backendClient.getCollective(params.collectiveSlug),
    launchDarklyClient.getFeatureFlags(),
  ]);

  if (!collective) {
    return collectiveNotFoundRedirect(params.collectiveSlug);
  }

  return {
    props: {
      gtmId: GOOGLE_TAG_MANAGER_ID,
      datadogService: DATADOG_SERVICE,
      datadogApplicationId: DATADOG_PUBLIC_PAGES_APPLICATION_ID,
      initialFeatureFlags: featureFlags,
      collective,
    },
  };
};

const CollectivePage = ({ collective }: CollectivePageProps) => {
  usePageReady(collective.id);
  const title = collective.name;

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <NextSeo
        title={title}
        description={collective.tagline || undefined}
        openGraph={{
          url: getCollectivePageUrl(collective.slug),
          title,
          description: collective.tagline || undefined,
          images: [
            ...(collective.logoUrl
              ? [
                  {
                    url: collective.logoUrl,
                    width: 92,
                    height: 92,
                    alt: 'Collective profile picture',
                    type: 'image/png',
                  },
                ]
              : []),
            ...(collective.coverUrl
              ? [
                  {
                    url: collective.coverUrl,
                    width: 1584,
                    height: 396,
                    alt: 'Collective cover image',
                    type: 'image/png',
                  },
                ]
              : []),
          ],
          site_name: title,
        }}
      />
      <PublicPageContextProvider getCaseStudyPageLink={toCollectiveCaseStudy}>
        <CollectivePublicPage collective={collective} />
      </PublicPageContextProvider>
    </>
  );
};

export default CollectivePage;
